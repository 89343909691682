.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid orange;
}

.footer{
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 8rem;

}

.social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.logo-f>img{
    width: 10rem;
}

.footer-blur-1{
    bottom: 0;
    right: 20%;
    width: 26rem;
    height: 12rem;
    filter: blur(160px);
    background: rgba(253, 120, 43, 0.69);
}
.footer-blur-2{
    bottom: 0;
    left: 30%;
    width: 26rem;
    height: 12rem;
    filter: blur(160px);
    background: rgba(253, 120, 43, 0.69);
}


@media screen and (max-width:768px){
    .footer{
        display: flex;
        flex-direction: column;
    }
    .social-links{
        display: flex;
        flex-direction: row;
        gap: 4rem;
    }

}